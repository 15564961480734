import { Message } from "@/types/Message";
import { Talk } from "@/types/Talk";
import cloneDeep from "lodash/cloneDeep";
import orderBy from "lodash/orderBy";
import { extractMessage } from "./message-process/extractMsg";
import { transformMessageList } from "./message";
import { removeTyping } from "./message-process/removeTyping";
import { removeTempMsg } from "./message-process/removeTempMsg";
import { getLatestMsg } from "./message-process/getLatestMsg";
import { getSpokenFlag } from "./message-process/getSpokenFlag";
import { getOfflineFlag } from "./getOfflineFlag";
import { getNewCountAndWorkerReadFlag } from "./message-process/getNewCountAndWorkerReadFlag";
import { getWaitingFlag } from "./message-process/getWaitingFlag";
import { playNewMsgSound } from "./audio";
import { getAIReplyStatus, isAIPreview } from "./message-process/ai";
import { removeRecallMsg } from "./message-process/removeRecallMsg";

export function getPlatformType(rawPlatform: string) {
  if (rawPlatform === "pc" || rawPlatform === "mobile") {
    return "web";
  } else {
    return rawPlatform;
  }
}

export function sanitizeNewTalkList(oldList: Talk[], newList: Talk[]) {
  newList = cloneDeep(newList);

  let sanitizedList: Talk[] = [];

  // 必然存在的属性列表
  const definateProperties = [
    "uid",
    "microtime",
    "total",
    "name",
    "worker",
    "tags",
  ];

  // 可能存在的属性列表
  const possibleProperties = [
    {
      key: "nickname",
      defaultVal: "",
    },
    {
      key: "uaicons",
      defaultVal: [
        {
          icon: "unknown_os",
          text: "未知",
        },
        {
          icon: "unknown_brand",
          text: "未知",
        },
      ],
    },
    {
      key: "avatar",
      defaultVal: "",
    },
    {
      key: "referrer",
      defaultVal: null,
    },
    {
      key: "gender",
      defaultVal: 0,
    },
    {
      key: "wxappid",
    },
    {
      key: "appid",
    },
    {
      key: "weibo_uid",
    },
    {
      key: "ip",
    },
    {
      key: "isActive",
      defaultVal: false,
    },
    {
      key: "can_pm",
    },
    {
      key: "newestToReplyMsg",
    },
    {
      key: "toReplyMsg",
    },
    {
      key: "draft",
    },
    {
      key: "qrcode_name",
    },
  ];

  sanitizedList = newList
    .map((newTalk: Talk) => {
      const sanitizedTalk: any = {};
      const oldTalk = oldList.find((talk) => talk.uid === newTalk.uid);

      // 可以预先特殊处理的属性
      if (newTalk.platform) {
        sanitizedTalk.rawPlatform = newTalk.platform;
        sanitizedTalk.platform = getPlatformType(newTalk.platform);
      } else {
        if (!oldTalk) {
          return null;
        }
        sanitizedTalk.platform = oldTalk.platform;
        sanitizedTalk.rawPlatform = oldTalk.rawPlatform;
      }

      sanitizedTalk.from = newTalk.total;

      // conversation 预处理
      newTalk.conversation.forEach((item: Message, index, arr) => {
        if (item.style && item.style === "hidden") {
          arr.splice(index, 1);
        }
      });

      newTalk.conversation = extractMessage(
        transformMessageList(
          newTalk.conversation,
          sanitizedTalk.platform,
          newTalk.uid
        )
      );

      //要获取最后一条刷新消息
      const reversed = [...newTalk.conversation].reverse();
      const refreshMsg = reversed.find((m: any) =>
        [
          "refresh_user_info",
          "refresh_wechat_user_info",
          "user_authorization_revoke",
        ].includes(m.type)
      );
      if (refreshMsg) {
        newTalk = {
          ...newTalk,
          ...refreshMsg?.ext,
        };
      }

      sanitizedTalk.shouldRefreshInfoFlag = newTalk.conversation.some(
        (msg: Message) => {
          return (
            ["tags_add", "tags_remove", "lead_edit"].includes(msg.feType) ||
            msg.type === "refresh_user_info"
          );
        }
      );

      sanitizedTalk.conversation = oldTalk
        ? oldTalk.conversation.concat(newTalk.conversation)
        : newTalk.conversation;

      sanitizedTalk.conversation = removeRecallMsg(
        removeTyping(removeTempMsg(sanitizedTalk.conversation))
      );


      // 直接覆盖的属性
      definateProperties.forEach((key: string) => {
        sanitizedTalk[key] = newTalk[key];
      });

      // 选填属性
      possibleProperties.forEach(({ key, defaultVal }) => {
        if (typeof newTalk[key] !== "undefined") {
          sanitizedTalk[key] = newTalk[key];
        } else if (oldTalk && oldTalk[key]) {
          sanitizedTalk[key] = oldTalk[key];
        } else if (typeof defaultVal !== "undefined") {
          sanitizedTalk[key] = defaultVal;
        }
      });

      // 继续特殊处理属性

      // ai状态
      if (oldTalk?.isAIPreview) {
        sanitizedTalk.isAIPreview = true;
      }

      //默认值
      sanitizedTalk.showAISwitch = false;
      if (["wechat", "douyin", "web", 'kuaishou', 'redbook'].includes(sanitizedTalk.platform)) {
        const newIsAIPreview = isAIPreview(newTalk);
        if (newIsAIPreview) {
          sanitizedTalk.isAIPreview = true;
        }

        const newAiStatus = getAIReplyStatus(
          newTalk,
          sanitizedTalk.isAIPreview
        );

        if (newAiStatus === "void") {
          if (!oldTalk) {
            sanitizedTalk.showAISwitch = false;
          } else {
            sanitizedTalk.showAISwitch = oldTalk.showAISwitch;
            sanitizedTalk.aiStatus = oldTalk.aiStatus;
          }
        } else if (newAiStatus === "closed") {
          sanitizedTalk.showAISwitch = false;
        } else {
          sanitizedTalk.showAISwitch = true;
          sanitizedTalk.aiStatus = newAiStatus;
        }
      }

      //网页渠道，关键词后续刷新
      const refreshMsgWeb = newTalk.conversation.find(
        (m) => m.cmd === "refresh_user_info"
      );
      if (refreshMsgWeb) {
        if (!sanitizedTalk.referrer) sanitizedTalk.referrer = {};
        sanitizedTalk.referrer = {
          ...sanitizedTalk.referrer,
          keyword: refreshMsgWeb.body?.keyword || "",
        };
      }

      // 网页地址直辖市可能有重复
      if (newTalk.iplocation) {
        ["北京", "上海", "重庆", "天津", "保留地址\t保留地址"].forEach(
          (item) => {
            newTalk.iplocation = newTalk.iplocation!.replace(item, "");
          }
        );
      }

      let newFeLocation =
        newTalk.wxlocation || newTalk.iplocation || newTalk.location;

      if (newFeLocation) {
        newFeLocation = newFeLocation
          .replace(/\s/g, "")
          .replace("中国", "")
          .replace("上海上海", "上海")
          .replace("北京北京", "北京")
          .replace("天津天津", "天津")
          .replace("重庆重庆", "重庆");
        sanitizedTalk.feLocation = newFeLocation;
      } else if (oldTalk && oldTalk.feLocation) {
        sanitizedTalk.feLocation = oldTalk.feLocation;
      }

      // 微信广告来源
      if (newTalk.subscribe_from) {
        sanitizedTalk.subscribe_from = newTalk.subscribe_from;
        sanitizedTalk.subscribe_ad = newTalk.subscribe_ad;

        if (sanitizedTalk.subscribe_ad?.campaign_name) {
          sanitizedTalk.subscribe_adText =
            sanitizedTalk.subscribe_ad.campaign_name;
          sanitizedTalk.subscribe_adTextType = "广告计划";
        } else if (sanitizedTalk.subscribe_ad?.adgroup_name) {
          sanitizedTalk.subscribe_adText =
            sanitizedTalk.subscribe_ad.adgroup_name;
          sanitizedTalk.subscribe_adTextType = "广告";
        } else if (sanitizedTalk.subscribe_ad?.campaign_id) {
          sanitizedTalk.subscribe_adText = `ID(${sanitizedTalk.subscribe_ad.campaign_id})`;
          sanitizedTalk.subscribe_adTextType = "广告计划";
        } else if (sanitizedTalk.subscribe_ad?.adgroup_id) {
          sanitizedTalk.subscribe_adText = `ID(${sanitizedTalk.subscribe_ad.adgroup_id})`;
          sanitizedTalk.subscribe_adTextType = "广告";
        } else {
          sanitizedTalk.subscribe_adText = "";
        }
      } else if (oldTalk && oldTalk.subscribe_from) {
        sanitizedTalk.subscribe_from = oldTalk.subscribe_from;
        sanitizedTalk.subscribe_ad = oldTalk.subscribe_ad;
        sanitizedTalk.subscribe_adText = oldTalk.subscribe_adText;
      }
      if (
        sanitizedTalk.subscribe_from === "扫描二维码" &&
        sanitizedTalk.qrcode_name
      ) {
        sanitizedTalk.subscribe_from = "渠道二维码";
      }

      // 非微信广告来源
      if (
        newTalk.subscribe_ad &&
        ["douyin", "kuaishou", "redbook", "web"].includes(sanitizedTalk.platform)
      ) {
        sanitizedTalk.subscribe_ad = newTalk.subscribe_ad;
        if (sanitizedTalk.subscribe_ad.campaign_name) {
          sanitizedTalk.subscribe_adText =
            sanitizedTalk.subscribe_ad.campaign_name;
        } else if (sanitizedTalk.subscribe_ad.campaign_id) {
          sanitizedTalk.subscribe_adText = `ID(${sanitizedTalk.subscribe_ad.campaign_id})`;
        } else {
          sanitizedTalk.subscribe_adText = "";
        }
      } else if (oldTalk && oldTalk.subscribe_ad) {
        sanitizedTalk.subscribe_ad = oldTalk.subscribe_ad;
        sanitizedTalk.subscribe_adText = oldTalk.subscribe_adText;
      }

      if (sanitizedTalk.wxappid) {
        sanitizedTalk.appid = sanitizedTalk.wxappid;
      }

      sanitizedTalk.latestMsgText = getLatestMsg(sanitizedTalk);
      sanitizedTalk.spokenFlag = getSpokenFlag(sanitizedTalk);
      sanitizedTalk.offlineFlag = getOfflineFlag(sanitizedTalk);

      const waitingFlag = getWaitingFlag(sanitizedTalk);
      sanitizedTalk.waitingFlag = waitingFlag;

      // newCount计算
      const [newCount, workerReadFlag]: any =
        getNewCountAndWorkerReadFlag(newTalk);

      if (oldTalk && oldTalk.newCount && !workerReadFlag) {
        sanitizedTalk.newCount = oldTalk.newCount + newCount;
      } else {
        sanitizedTalk.newCount = newCount;
      }
      sanitizedTalk.hasNewMsgFlag = !!newCount && !sanitizedTalk.isHosted;

      if (sanitizedTalk.isActive && document.hasFocus()) {
        sanitizedTalk.newCount = 0;
      }

      return sanitizedTalk as Talk;
    })
    .filter((talk) => {
      return !!talk;
    }) as Talk[];

  sanitizedList = orderBy(
    sanitizedList,
    ["waitingFlag", "offlineFlag", "spokenFlag", "microtime"],
    ["desc", "asc", "desc", "desc"]
  );

  handleTalkList(sanitizedList);

  return sanitizedList;
}

function handleTalkList(talkList: Talk[]) {
  let totalNewCount = 0;
  let hasNewMsgFlag = false;
  talkList.forEach((talk: any) => {
    if (talk.hasNewMsgFlag) {
      hasNewMsgFlag = true;
    }
    totalNewCount += talk.newCount;
  });

  if (hasNewMsgFlag) {
    // this.audioService.playNewMessage();
    playNewMsgSound();
  }
}

export function transformTalkListToFe(talkList: any): Talk[] {
  talkList = cloneDeep(talkList);
  return Object.entries(talkList).map(([uid, val]: [string, any]) => {
    val.uid = uid;
    return { ...val };
  });
}
