<template>
  <van-nav-bar @click-left="goBack" left-arrow>
    <template #left>
      <div class="left-container">
        <van-badge :content="chatOtherTotalNewCount || null">
          <i
            class="
              van-badge__wrapper
              van-icon van-icon-arrow-left
              van-nav-bar__arrow
            "
          ></i>
          <span class="return-text">返回</span>
        </van-badge>
      </div>
    </template>

    <template #title>
      <div class="title-slot">
        <span class="title-container">
          {{ currentVisitor?.name || currentVisitor?.nickname }}
          <template v-if="currentVisitor?.feLocation"
            >({{ currentVisitor?.feLocation }})</template
          >
        </span>

        <chat-page-subtitle></chat-page-subtitle>
      </div>

      <!-- template -->
    </template>
  </van-nav-bar>

  <div class="top-action-panel">
    <div class="top-action" @click="showDialog = true">访客来源</div>
    <div class="top-action" @click="editLead">编辑客资</div>
    <div class="top-action" @click="openTransfer">转接对话</div>
  </div>

  <div
    class="message-list"
    @click="closePanel"
    v-bind:style="{
      bottom: inputContainerHeight + 'px',
    }"
    v-chat-scroll="{ always: false, smooth: true }"
  >
    <div v-if="chatScrollFix"></div>
    <yunque-message
      v-for="message in historyConversation"
      :message="message"
      :key="message.microtime"
      @click="clickHistoryMessage"
    ></yunque-message>
    <yunque-message
      v-for="message in currentVisitor?.conversation"
      :message="message"
      :key="message.microtime"
      @click="clickHistoryMessage"
    ></yunque-message>
  </div>

  <message-input></message-input>

  <van-popup v-model:show="showTransferPicker" position="bottom">
    <van-picker
      title="转接对话"
      :columns="workerList"
      @confirm="onTransfer"
      @cancel="showTransferPicker = false"
      :default-index="0"
    />
  </van-popup>

  <van-dialog v-model:show="showDialog">
    <source-dialog> </source-dialog>
  </van-dialog>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import Message from "@/components/messages/Message.vue";
import MessageInput from "@/components/MessageInput.vue";
import ChatPageSubtitle from "@/components/ChatPageSubtitle.vue";
import SourceDialog from "@/components/SourceDialog.vue";
import {
  getDouyinHistoryDetail,
  getWebHistoryDetail,
  getWechatHistoryDetail,
  getWeiboHistoryDetail,
  getKuaishouHistoryDetail,
  getRedbookuHistoryDetail,
} from "@/api/history";
import { transformMessageList } from "@/utils/message";
import { getOnlineWorkers, transfer } from "@/api/common";

import { NavBar, Badge, Toast, Popup, Picker, Dialog } from "vant";

export default defineComponent({
  components: {
    [NavBar.name]: NavBar,
    [Badge.name]: Badge,
    "yunque-message": Message,
    "message-input": MessageInput,
    "chat-page-subtitle": ChatPageSubtitle,
    "source-dialog": SourceDialog,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Dialog.Component.name]: Dialog.Component,
  },

  data() {
    return {
      historyConversation: [],
      chatScrollFix: false,
      showTransferPicker: false,
      workerList: [],
      showDialog: false,
    };
  },

  computed: {
    ...mapGetters({
      currentVisitor: "currentVisitor",
      chatOtherTotalNewCount: "chatOtherTotalNewCount",
      toReplyCMT: "toReplyCMT",
    }),

    ...mapState({
      otherTotalNewCount: (state) => state.chat.otherTotalNewCount,
      wechatApps: (state) => state.common.wechatAppsObj,
      weiboApps: (state) => state.common.weiboAppsObj,
      douyinApps: (state) => state.common.douyinAppsObj,
      kuaishouApps: (state) => state.common.kuaishouAppsObj,
      redbookApps: (state) => state.common.redbookAppsObj,
      companyID: (state) => state.auth.companyID,
      inputContainerHeight: (state) => state.chat.inputContainerHeight,
    }),
  },

  watch: {
    currentVisitor() {
      if (!this.currentVisitor) {
        this.$router.replace("/chat-list");
      }
    },
  },

  beforeMount() {
    this.$store.commit("SET_CURRENT_VISITOR", this.$route.query.uid);
    this.$store.commit("UPDATE_INPUT_PULLEDUP", false);
  },

  mounted() {
    if (!this.currentVisitor) {
      this.$router.replace("/chat-list");
    } else {
      this.getHistory();
    }
  },

  beforeUnmount() {
    this.$store.commit("UPDATE_TO_REPLY_CMT", null);
  },

  methods: {
    goBack() {
      this.$router.push("/chat-list");
    },

    closePanel() {
      this.$store.commit("UPDATE_INPUT_PULLEDUP", false);
      this.$store.commit("RESET_INPUT_PULLUP_HEIGHT");
    },

    getHistory(last_id) {
      let obs;

      switch (this.currentVisitor?.platform) {
        case "web":
          obs = getWebHistoryDetail(
            this.currentVisitor.uid,
            last_id,
            this.companyID
          );
          break;
        case "wechat":
          obs = getWechatHistoryDetail(this.currentVisitor.uid, last_id);
          break;

        case "weibo":
          obs = getWeiboHistoryDetail(this.currentVisitor.uid, last_id);

          break;
        case "douyin":
          obs = getDouyinHistoryDetail(this.currentVisitor.uid, last_id);
          break;
        case "kuaishou":
          obs = getKuaishouHistoryDetail(this.currentVisitor.uid, last_id);
          break;
        case "redbook":
          obs = getRedbookuHistoryDetail(this.currentVisitor.uid, last_id);
          break;

        default:
          break;
      }

      obs?.subscribe((res) => {
        this.chatScrollFix = true;
        if (!res.data.length) return;
        if (typeof last_id !== "undefined") {
          this.historyConversation = res.data
            .slice()
            .concat(this.historyConversation);
        } else {
          // const latestMicrotime =
          //   res.data[0].microtime || res.data[1].microtime;

          this.historyConversation = transformMessageList(
            [
              {
                feType: "history",
                microtime: 0,
                body: null,
              },
            ],
            this.currentVisitor.platform,
            this.currentVisitor.uid
          );
        }
      });
    },

    clickHistoryMessage(data) {
      this.historyConversation.shift();
      this.getHistory(data.microtime);
    },

    editLead() {
      this.$router.push({
        path: "/edit-lead",
        query: {
          uid: this.currentVisitor.uid,
        },
      });
    },

    onTransfer(val) {
      this.showTransferPicker = false;
      transfer({
        uid: this.currentVisitor.uid,
        platform: this.currentVisitor.rawPlatform,
        new_worker_name: val.value,
      }).subscribe(
        () => {
          Toast.success("转接成功");
          this.$router.replace(`/chat-list`);
        },
        () => {
          this.$router.replace(`/chat-list`);
        }
      );
    },

    openTransfer() {
      this.showTransferPicker = true;
      getOnlineWorkers().subscribe((list) => {
        this.workerList = list
          .filter((worker) => {
            return worker.name !== localStorage.getItem("workername");
          })
          .map((worker) => {
            return {
              text:
                worker.name +
                " " +
                worker.nickname +
                `${worker.online ? "" : "(手机在线)"}`,
              value: worker.name,
            };
          });
      });
    },
  },
});
</script>

<style lang="less" scoped>
.left-container {
  color: #ddd;
  opacity: 1;
  display: flex;
  align-items: center;

  .return-text {
    margin-left: -3px;
  }
}

.title-slot {
  display: flex;
  flex-direction: column;
  line-height: 0;

  i.iconfont {
    font-size: 11px;
    line-height: 15px;
  }

  .title-container {
    line-height: 20px;
    font-size: 16px;
    text-align: center;
  }
}

.message-list {
  padding: 10px;
  padding-bottom: 0;
  position: absolute;
  top: 96px;
  bottom: 50px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

.top-action-panel {
  height: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;

  .top-action {
    flex: 1;
    text-align: center;
    font-size: 14px;
  }

  .top-action:last-child {
    border-left: 1px solid #ddd;
  }

  .top-action:first-child {
    border-right: 1px solid #ddd;
  }
}
</style>
