<template>
  <router-view />
</template>

<style lang="less">
@font-face {
  font-family: 'iconfont';  /* Project id 211302 */
  src: url('//at.alicdn.com/t/c/font_211302_ykcu7e1r4q.woff2?t=1721114445383') format('woff2'),
       url('//at.alicdn.com/t/c/font_211302_ykcu7e1r4q.woff?t=1721114445383') format('woff'),
       url('//at.alicdn.com/t/c/font_211302_ykcu7e1r4q.ttf?t=1721114445383') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
}
</style>
