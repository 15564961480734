<template>
  <div class="subtitle-container">
    <template v-if="platform === 'web'">
      <i class="iconfont">&#xe6f5;</i>
    </template>

    <template v-if="platform === 'wechat'">
      <i class="iconfont">&#xe600;</i>
    </template>

    <template v-if="platform === 'weibo'">
      <i class="iconfont">&#xe62f;</i>
    </template>

    <template v-if="platform === 'douyin'">
      <i class="iconfont">&#xe607;</i>
    </template>

    <template v-if="platform === 'redbook'">
      <i class="iconfont">&#xe7eb;</i>
    </template>

    <template v-if="platform === 'kuaishou'">
      <i class="iconfont">&#xe66f;</i>
    </template>

    <span class="subtitle">
      {{ subTtile }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { DefaultSourceMap } from "@/data/source";

export default defineComponent({
  name: "HistoryDetailPageSubtitle",
  props: {
    detail: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      wechatApps: (state) => state.common.wechatAppsObj,
      weiboApps: (state) => state.common.weiboAppsObj,
      douyinApps: (state) => state.common.douyinAppsObj,
      kuaishouApps: (state) => state.common.kuaishouAppsObj,
      redbookApps: (state) => state.common.redbookAppsObj,
    }),
    platform: function () {
      return this.detail?.platform || "";
    },
    subTtile: function () {
      if (!this.detail) return "";
      let displayText = "";
      switch (this.detail.platform) {
        case "web":
          return this.getWebSubtitle();
        case "wechat":
          return this.getWechatSubTitle();
        case "weibo":
          return this.weiboApps[this.detail.appid]?.name;
        case "douyin":
          displayText = this.douyinApps[this.detail.appid]?.name;
          if (this.detail.subscribe_ad?.campaign_name) {
            displayText +=
              " - 广告计划: " + this.detail.subscribe_ad.campaign_name;
          }
          return displayText;
        case "kuaishou":
        return this.getKuaishouSubTitle();
        case "redbook":
          displayText = this.redbookApps[this.detail.appid]?.name;
         
          if (this.detail.subscribe_ad?.campaign_name) {
            displayText +=
              " - 广告计划: " + this.detail.subscribe_ad.campaign_name;
          }
          return displayText;
        default:
          return "";
      }
    },
  },

  beforeMount() {},

  methods: {
    getWebSubtitle() {
      const source = this.detail.source;
      let displayText = "";

      if (source === "search") {
        displayText = DefaultSourceMap[this.detail.engine];
      } else if (source === "custom") {
        displayText = `自定义(${this.detail.custom_source})`;
      } else {
        displayText = DefaultSourceMap[source];
      }

      if (this.detail.keyword) {
        displayText += `(${this.detail.keyword})`;
      }

      return displayText;
    },

    getWechatSubTitle() {
      const wechatApp = this.wechatApps[this.detail.appid];

      let displayText = "";

      if (wechatApp?.corp_name) {
        displayText = `${wechatApp.name} @${wechatApp.corp_name}`;
      } else {
        displayText = wechatApp?.name;
      }

      if (this.detail.subscribe_from) {
        let subscribeText = `${this.detail.subscribe_from}`;

        if (this.detail.subscribe_ad) {
          if (this.detail.subscribe_ad.campaign_name) {
            subscribeText += ` - 广告计划: ${this.detail.subscribe_ad.campaign_name}`;
          } else if (this.detail.subscribe_ad.adgroup_name) {
            subscribeText += ` - 广告: ${this.detail.subscribe_ad?.adgroup_name}`;
          } else if (this.detail.subscribe_ad.campaign_id) {
            subscribeText += ` - 广告计划: ${this.detail.subscribe_ad.campaign_id}`;
          } else if (this.detail.subscribe_ad.adgroup_id) {
            subscribeText += ` - 广告: ${this.detail.subscribe_ad.adgroup_id}`;
          }
        }

        if (this.detail.qrcode_name) {
          subscribeText += `-${this.detail.qrcode_name}`;
        }

        displayText += `-${subscribeText}`;
      }

      return displayText;
    },

    getKuaishouSubTitle() {
      let displayText = this.kuaishouApps[this.detail.appid]?.name || '已解绑';
    
        let suffix = "";
      if (this.detail.subscribe_scene === "ad") {
        suffix = "-广告";
      } else if (this.detail.subscribe_scene === "normal") {
        suffix = "-自然流量";
      } else if (this.detail.subscribe_scene === "unknown") {
        suffix = "-未知";
      }

      if (this.detail.subscribe_ad?.campaign_name) {
        suffix +=
          "-广告计划: " + this.detail.subscribe_ad.campaign_name;
      }



      return displayText + suffix;
    }

   
  },
});
</script>

<style lang="less" scoped>
.subtitle-container {
  line-height: 15px;
  font-size: 11px;

  display: flex;
  align-items: center;
  justify-content: center;

  .subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
