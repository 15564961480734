<template>
    <div class="container">
      <template v-if="currentHistoryVisitor.platform !== 'web'"
        ><b>账号：</b> {{ sourceText }}
      </template>
      <div class="item" v-if="currentHistoryVisitor.platform === 'web'">
        <b>来源：</b> {{ sourceText }}
      </div>
  
      <template
        v-if="currentHistoryVisitor.platform === 'web' && currentHistoryVisitor.subscribe_ad"
      >
        <div
          class="item"
          v-if="
            currentHistoryVisitor.subscribe_ad.campaign_id &&
            currentHistoryVisitor.subscribe_ad.campaign_id !== '0'
          "
        >
          <b>广告计划：</b>
          {{
            currentHistoryVisitor.subscribe_ad.campaign_name
              ? currentHistoryVisitor.subscribe_ad.campaign_name
              : "ID"
          }}
          ({{ currentHistoryVisitor.subscribe_ad.campaign_id }})
        </div>
  
        <div
          class="item"
          v-if="
            currentHistoryVisitor.subscribe_ad.adgroup_id &&
            currentHistoryVisitor.subscribe_ad.adgroup_id !== '0'
          "
        >
          <b>广告：</b>
          {{
            currentHistoryVisitor.subscribe_ad.adgroup_name
              ? currentHistoryVisitor.subscribe_ad.adgroup_name
              : "ID"
          }}
          ({{ currentHistoryVisitor.subscribe_ad.adgroup_id }})
        </div>
  
        <div
          class="item"
          v-if="
            currentHistoryVisitor.subscribe_ad.creative_id &&
            currentHistoryVisitor.subscribe_ad.creative_id !== '0'
          "
        >
          <b>创意：</b>
          {{ currentHistoryVisitor.subscribe_ad.creative_name }} ({{
            currentHistoryVisitor.subscribe_ad.creative_id
          }})
        </div>
  
        <div
          class="item"
          v-if="
            currentHistoryVisitor.subscribe_ad.ad_account_id &&
            currentHistoryVisitor.subscribe_ad.ad_account_id !== '0'
          "
        >
          <b>广告主ID：</b>
          {{ currentHistoryVisitor.subscribe_ad.ad_account_id }}
        </div>
  
        <div class="item" v-if="currentHistoryVisitor.subscribe_ad.fmc_account_id">
          <b>组件账户ID：</b>
          {{ currentHistoryVisitor.subscribe_ad.fmc_account_id }}
        </div>
      </template>
  
      <template
        v-if="
          currentHistoryVisitor.platform === 'wechat' && currentHistoryVisitor.subscribe_from
        "
      >
        <div class="item">
          <b>来源：</b> {{ currentHistoryVisitor.subscribe_from }}
          <template v-if="currentHistoryVisitor.qrcode_name">
            - {{ currentHistoryVisitor.qrcode_name }}
          </template>
        </div>
      </template>
  
      <template
        v-if="currentHistoryVisitor.platform === 'wechat' && currentHistoryVisitor.subscribe_ad"
      >
        <div class="item" v-if="currentHistoryVisitor.subscribe_ad.campaign_id !== '0'">
          <b>广告计划：</b>
          {{
            currentHistoryVisitor.subscribe_ad.campaign_name
              ? currentHistoryVisitor.subscribe_ad.campaign_name
              : "ID"
          }}
          ({{ currentHistoryVisitor.subscribe_ad.campaign_id }})
        </div>
  
        <div class="item" v-if="currentHistoryVisitor.subscribe_ad.adgroup_id">
          <b>广告：</b>
          {{
            currentHistoryVisitor.subscribe_ad.adgroup_name
              ? currentHistoryVisitor.subscribe_ad.adgroup_name
              : "ID"
          }}
          ({{ currentHistoryVisitor.subscribe_ad.adgroup_id }})
        </div>
  
        <div class="item" v-if="currentHistoryVisitor.subscribe_ad.ad_creative_id">
          <b>创意：</b>
          {{ currentHistoryVisitor.subscribe_ad.ad_creative_name }} ({{
            currentHistoryVisitor.subscribe_ad.ad_creative_id
          }})
        </div>
  
        <div class="item" v-if="currentHistoryVisitor.subscribe_ad.creative_id">
          <b>创意：</b>
          {{ currentHistoryVisitor.subscribe_ad.creative_name }} ({{
            currentHistoryVisitor.subscribe_ad.creative_id
          }})
        </div>
  
        <div class="item" v-if="currentHistoryVisitor.subscribe_ad.position">
          <b>广告位置：</b>
          {{ currentHistoryVisitor.subscribe_ad.position }}
        </div>
  
        <div class="item" v-if="currentHistoryVisitor.subscribe_ad.account_id">
          <b>广告主：</b>
          {{
            currentHistoryVisitor.subscribe_ad.account_name
              ? currentHistoryVisitor.subscribe_ad.account_name
              : "ID"
          }}
          ({{ currentHistoryVisitor.subscribe_ad.account_id }} )
        </div>
      </template>
  
      <template
        v-if="currentHistoryVisitor.platform === 'douyin' && currentHistoryVisitor.subscribe_ad"
      >
        <div class="item">
          <b>广告计划：</b>
          {{
            currentHistoryVisitor.subscribe_ad.campaign_name
              ? currentHistoryVisitor.subscribe_ad.campaign_name
              : "ID"
          }}
          ({{ currentHistoryVisitor.subscribe_ad.campaign_id }})
        </div>
  
        <div class="item">
          <b>广告ID：</b>
  
          ({{ currentHistoryVisitor.subscribe_ad.adgroup_id }})
        </div>
  
        <div class="item">
          <b>创意ID：</b>
          {{ currentHistoryVisitor.subscribe_ad.creative_id }}
        </div>
  
        <div class="item">
          <b>广告主ID：</b>
          {{ currentHistoryVisitor.subscribe_ad.account_id }}
        </div>
      </template>
  
      <template
        v-if="
          currentHistoryVisitor.platform === 'redbook' && currentHistoryVisitor.subscribe_ad
        "
      >
        <div class="item">
          <b>广告计划：</b>
          {{
            currentHistoryVisitor.subscribe_ad.campaign_name
              ? currentHistoryVisitor.subscribe_ad.campaign_name
              : "ID"
          }}
          ({{ currentHistoryVisitor.subscribe_ad.campaign_id }})
        </div>
  
        <div class="item">
          <b>创意：</b>
          {{
            currentHistoryVisitor.subscribe_ad.creative_name
              ? currentHistoryVisitor.subscribe_ad.creative_name
              : "ID"
          }}
          ({{ currentHistoryVisitor.subscribe_ad.creative_id }})
        </div>
  
        <div class="item">
          <b>广告主：</b>
          {{
            currentHistoryVisitor.subscribe_ad.account_name
              ? currentHistoryVisitor.subscribe_ad.account_name
              : "ID"
          }}
          ({{ currentHistoryVisitor.subscribe_ad.account_id }})
        </div>
      </template>
  
      <template
        v-if="
          currentHistoryVisitor.platform === 'kuaishou' && currentHistoryVisitor.subscribe_ad
        "
      >
        <div class="item">
          <b>广告计划：</b>
          {{
            currentHistoryVisitor.subscribe_ad.campaign_name
              ? currentHistoryVisitor.subscribe_ad.campaign_name
              : "ID"
          }}
          ({{ currentHistoryVisitor.subscribe_ad.campaign_id }})
        </div>
  
        <div class="item">
          <b>广告组ID：</b>
          {{ currentHistoryVisitor.subscribe_ad.adgroup_id }}
        </div>
  
        <div class="item">
          <b>创意ID：</b>
          {{ currentHistoryVisitor.subscribe_ad.creative_id }}
        </div>
      </template>
  
      <template
        v-if="
          currentHistoryVisitor.platform === 'kuaishou' &&
          currentHistoryVisitor.subscribe_scene &&
          !currentHistoryVisitor.subscribe_ad
        "
      >
        <div class="item">
          <b>来源：</b>
        
          <template v-if="currentHistoryVisitor.subscribe_scene === 'unknown'">未知</template>
          <template v-if="currentHistoryVisitor.subscribe_scene === 'normal'">自然流量</template>
        </div>
      </template>
    </div>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import { mapGetters, mapState } from "vuex";
  import { DefaultSourceMap } from "@/data/source";
  
  export default defineComponent({
    name: "HistorySourceDialog",

    props: {
      currentHistoryVisitor: Object
    },
  
    data() {
      return {};
    },
  
    beforeMount() {
    },
  
    computed: {
    
      ...mapState({
        wechatApps: (state) => state.common.wechatAppsObj,
        weiboApps: (state) => state.common.weiboAppsObj,
        douyinApps: (state) => state.common.douyinAppsObj,
        kuaishouApps: (state) => state.common.kuaishouAppsObj,
        redbookApps: (state) => state.common.redbookAppsObj,
      }),
      platform: function () {
        return this.currentHistoryVisitor?.platform || "";
      },
      sourceText: function () {
        if (!this.currentHistoryVisitor) return "";
        switch (this.currentHistoryVisitor.platform) {
          case "web":
            return this.getWebSourceText();
          case "wechat":
            return this.getWechatSourceText();
          case "weibo":
            return this.weiboApps[this.currentHistoryVisitor.appid]?.name;
          case "douyin":
            return this.douyinApps[this.currentHistoryVisitor.appid]?.name;
          case "kuaishou":
            return this.kuaishouApps[this.currentHistoryVisitor.appid]?.name;
          case "redbook":
            return this.redbookApps[this.currentHistoryVisitor.appid]?.name;
          default:
            return "";
        }
      },
    },
  
    methods: {
      getWebSourceText() {
        const source = this.currentHistoryVisitor.source;
        let displayText = "";
  
        if (source === "search") {
          displayText = DefaultSourceMap[this.currentHistoryVisitor.engine];
        } else if (source === "custom") {
          displayText = `自定义(${this.currentHistoryVisitor.custom_source})`;
        } else {
          displayText = DefaultSourceMap[source];
        }
  
        if (this.currentHistoryVisitor.keyword) {
          displayText += `(${this.currentHistoryVisitor.keyword})`;
        }
  
        return displayText;
      },
  
      getWechatSourceText() {
        const wechatApp = this.wechatApps[this.currentHistoryVisitor.appid];
  
        let displayText = "";
  
        if (wechatApp?.corp_name) {
          displayText = `微信客服（${wechatApp.name} @${wechatApp.corp_name}）`;
        } else {
          let prefix = "";
          switch (wechatApp?.type) {
            case 0:
              prefix = "微信公众号";
              break;
            case 2:
              prefix = "微信小程序";
              break;
            case 3:
              prefix = "微信客服";
              break;
            default:
              prefix = "微信公众号";
              break;
          }
          displayText = `${prefix}（${wechatApp?.name}）`;
        }
  
        return displayText;
      },
    },
  });
  </script>
  
  <style scoped>
  /* Component styles here */
  .container {
    padding: 20px;
    font-size: 15px;
  }
  </style>
  