<template>
  <div class="msg-wrapper msg-worker msg-douyin preline" v-if="message.feType === 'douyin_worker_text'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <span v-if="message.recalled">（已撤回）</span>
        <div v-html="douyinfaceFilter(message.text)"></div>

      </div>
      <div v-if="message.auto_typeText || message.is_recallable" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
        <span class="recall-text" v-if="message.is_recallable && showRecallBtn && !message.recalled" @click="recall">
          撤回
        </span>
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker msg-temp msg-douyin preline" v-if="message.feType === 'temp_douyin_worker_text'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="temp-status">
        <i class="iconfont sending" v-if="
          message.sendingStatus !== 'sent' &&
          message.sendingStatus !== 'error'
        ">&#xe609;</i>
        <i class="iconfont error" v-if="message.sendingStatus === 'error'">&#xe606;</i>
      </div>
      <div v-html="douyinfaceFilter(message.text)"></div>
      <div v-if="message.auto_typeText" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'douyin_worker_image'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <div v-if="message.recalled">（已撤回）</div>
        <img :src="message.url" />

      </div>
      <div v-if="message.auto_typeText || message.is_recallable" class="bubble-type bubble-type-long">
        {{ message.auto_typeText }}
        <span class="recall-text" v-if="message.is_recallable && showRecallBtn && !message.recalled" @click="recall">
          撤回
        </span>
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'douyin_worker_cmt'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <app-douyin-worker-cmt :message="message"></app-douyin-worker-cmt>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'douyin_worker_video'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble">
      <div class="inner-bubble">
        <template v-if="message.recalled">（已撤回）</template>

        <div class="douyin-worker-video-container">
          <div class="video-card">
            <p v-if="message.share_url">视频标题：{{ message.title || "" }}</p>
            <p v-if="!message.share_url">未知视频</p>
          </div>
        </div>
      </div>

      <div v-if="message.auto_typeText || message.is_recallable" class="bubble-type bubble-type-long">
        {{ message.auto_typeText || "视频" }}
        <span class="recall-text" v-if="message.is_recallable && showRecallBtn && !message.recalled" @click="recall">
          撤回
        </span>
      </div>
    </div>
  </div>

  <div class="msg-wrapper msg-worker" v-if="message.feType === 'douyin_worker_card'">
    <div class="msg-subtitle">
      {{ message.feDateTime }}
      <span v-if="message.worker">({{ message.worker.name }} {{ message.worker.nickname }})回复</span>
    </div>
    <div class="msg-bubble" style="background-color: white">
      <div class="inner-bubble">
        <div style="color: black;" v-if="message.recalled">（已撤回）</div>

        <app-douyin-card :cardInfo="message"></app-douyin-card>

      </div>

      <div v-if="message.auto_typeText || message.is_recallable" class="bubble-type bubble-type-long">
        {{ message.auto_typeText || "卡片消息" }}
        <span class="recall-text" v-if="message.is_recallable && showRecallBtn && !message.recalled" @click="recall">
          撤回
        </span>
      </div>

    
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  MessageFeFrom,
  MessagePlatform,
  MessageCategory,
  WeiboMessageDivision,
} from "@/types/Message";

import DouyinCard from "@/components/messages/special/DouyinCard.vue";
import DouyinWorkerCmt from "@/components/messages/special/DouyinWorkerCmt.vue";

import { DouyinfaceFilter as douyinface } from "@/filters/douyinface";
import { isInRecallTimeMargin } from '@/utils/message-process/removeRecallMsg';

import { Toast, Dialog } from "vant";
import { recallMsg } from "@/api/common";
export default defineComponent({
  props: {
    message: Object,
  },

  computed: {
    douyinfaceFilter() {
      return (v) => {
        return douyinface(v);
      };
    },
  },

  components: {
    "app-douyin-card": DouyinCard,
    "app-douyin-worker-cmt": DouyinWorkerCmt,
  },

  data() {
    return {
      showRecallBtn: false,
      messageFeFroms: MessageFeFrom,
      messagePlatforms: MessagePlatform,
      messageCategories: MessageCategory,
      WeiboMessageDivision: WeiboMessageDivision,
    };
  },

  beforeMount() {

    this.showRecallBtn = this.message.is_recallable && isInRecallTimeMargin(this.message.microtime || 0) && this.message.auto_typeText !== '关注追粉';

  },

  methods: {
    recall() {
      Dialog.confirm({
        message: "确定撤回消息吗？",
      })
        .then(() => {
          this.doRecall();
        }, () => {

        });
    },

    doRecall() {
      recallMsg({
        uid: this.message.uid,
        microtime: this.message.microtime
      }).subscribe(() => {
        Toast.success("撤回成功");
        this.$emit("onRecall", {
          microtime: this.message.microtime,
        });
      }, (err) => {
        Toast.fail(err.response.data.message);
      });
    }
  },
});
</script>